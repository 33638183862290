import { useState, useEffect } from 'react'
import { checkoutURL } from '../../ApiEndpoints';
import { useSelector } from 'react-redux';
import useSetCheckoutIframe from './useSetCheckoutIframe';
import Api from "../../services/Api";
import { mutate } from 'swr';
import { navigate } from 'gatsby';

function useCheckout() {
  const { user } = useSelector((state) => state.auth);
  const initialCartId = user?.["cart_id"];

  const [isCheckoutLoading, setCheckoutLoading] = useState(false)
  const [cartId, setCartId] = useState(null)

  const checkoutIframe = document.querySelector("#checkout-iframe");

  const { setIframe } = useSetCheckoutIframe();

  useEffect(async () => {
    if (!cartId || !checkoutIframe) {
      setCheckoutLoading(true);
      const checkoutURL = await checkoutCart(false);
      await setIframe(checkoutURL);
      handleDelayedUpdate(false);
    }
  }, []);

  const handleDelayedUpdate = (val) => {
    setTimeout(() => {
      setCheckoutLoading(val);
    }, 1500);
  };

  const checkoutCart = async (viaButton) => {
    try {
      const res = await getCheckoutUrl(checkoutURL(initialCartId));
      console.log('11-22 res: ', res);

      // when data is conflicting...
      if (res?.response?.status === 409) {
        // mutate cart...
        mutate("/cart");
      }

      if (res.status === 200) {
        const checkout_url = res?.data?.checkout_url;

        if (cartId !== res?.data?.cart_id || !checkoutIframe) {
          setIframe(checkout_url);
          setCartId(res.data.cart_id);
        }

        if (viaButton) {
          const changeCartPageStyle = () => {
            const checkoutIframe = document.querySelector("#checkout-iframe");

            checkoutIframe.style.width = "100%";
            checkoutIframe.style.minHeight = "100vh";
            checkoutIframe.style.overflow = "auto";
          };
          changeCartPageStyle();
          console.log('checkout button called');
          navigate("/checkout");
        } else {
          return checkout_url;
        }

      }

    } catch (e) {
      console.error(e);
    }
  };

  async function handleCheckoutClick() {
    await checkoutCart(true);
  };

  return {
    isCheckoutLoading,
    setCheckoutLoading,
    handleCheckoutClick
  }
}

async function getCheckoutUrl(url) {
  const response = await Api.post(url);
  return response;
}

export default useCheckout
